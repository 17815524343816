import React, { Component } from 'react';
import { Collapse, NavbarToggler } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Route } from 'react-router';

export class SideBar extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {

        return (<div>
            <NavbarToggler className="d-block d-md-none bg-dark sidebar" onClick={this.toggleNavbar}>
                <span style={{ color: "white" }}>View menu</span>
            </NavbarToggler>

            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar style={{ width: "100%", minWidth: "200px" }}>

                <nav className="sidebar pcoded-navbar menu-light" style={{ paddingTop: "10px" }}>
                    <div className="sidebar-sticky" style={{ width: "100%", minWidth: "200px" }}>
                        <ul className="nav flex-column pcoded-inner-navbar">

                            <Route path="/users">
                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                    <Link className="nav-link" to="/users">
                                        <span className="pcoded-micon"><i className="feather icon-users"></i></span>
                                        <span className="pcoded-mtext">Users</span>
                                    </Link>
                                </li>}
                            </Route>
                            <Route path="/invitations">
                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                    <Link className="nav-link" to="/invitations">
                                        <span className="pcoded-micon"><i className="feather icon-user-plus"></i></span>
                                        <span className="pcoded-mtext">Invitations</span>
                                    </Link>
                                </li>}
                            </Route>
                            <Route path="/user-types">
                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                    <Link className="nav-link" to="/user-types">
                                        <span className="pcoded-micon"><i className="feather icon-menu"></i></span>
                                        <span className="pcoded-mtext">User types</span>
                                    </Link>
                                </li>}
                            </Route>

                        </ul>
                    </div>
                </nav>
            </Collapse>
        </div>
        );
    }
}
