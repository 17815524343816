import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import { MillbrookAPI } from '../Infrastructure/MillbrookAPI';

export class UserTypesDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false };
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        return (
            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>
                                    <Link to="/user-types" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                    {this.state.data && <span>User Type</span>}
                                    {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                </h5>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">Name:</td>
                                            <td>
                                                {this.state.data.name && this.state.data.name}
                                                {!this.state.data.name && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Background Colour:</td>
                                            <td>
                                                {this.state.data.backgroundColour && <div>
                                                    <div style={{ height: "20px", width: "20px", display: "inline-block", marginRight: "10px", backgroundColor: this.state.data.backgroundColour }}></div>
                                                    {this.state.data.backgroundColour}
                                                </div>}
                                                {!this.state.data.backgroundColour && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Foreground Colour:</td>
                                            <td>
                                                {this.state.data.foregroundColour && <div>
                                                    <div style={{ height: "20px", width: "20px", display: "inline-block", marginRight: "10px", backgroundColor: this.state.data.foregroundColour }}></div>
                                                    {this.state.data.foregroundColour}
                                                </div>}
                                                {!this.state.data.foregroundColour && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Terms link:</td>
                                            <td>
                                                {this.state.data.termsLink && <Link to="{this.state.data.termsLink}">{this.state.data.termsLink}</Link>}
                                                {!this.state.data.termsLink && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Created:</td>
                                            <td>
                                                {this.state.data.created && <Moment format="ddd DD MMM, H:mma">
                                                    {this.state.data.created}
                                                </Moment>}
                                                {!this.state.data.created && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </Col>
                        </Row>
                    </CardHeader>
                </Card>
            </div>
        );
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await MillbrookAPI.GetUserTypeDetails(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }
}
