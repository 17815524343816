import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { MillbrookAPI, ListQueryParams } from '../Infrastructure/MillbrookAPI';
import { SelectField } from '../Infrastructure/SelectField';

export class InvitationsBulkUpload extends Component {

    constructor(props) {
        super(props);
        this.state = { apartmentOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                file: "",
                userTypeId: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.file) {
                    setFieldError("file", "Please choose a file to upload");
                    return;
                }
                if (!fields.userTypeId) {
                    setFieldError("userTypeId", "Please choose a user type");
                    return;
                }

                var response = await MillbrookAPI.InvitationsBulkUpload(fields.file, fields.userTypeId.value);

                if (!response.successful) {

                    response.data.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleBlur, handleChange }) => (
            <Form>
                {errors.csvData && <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                        <p style={{fontWeight: "bold", fontSize:"16px"}}>Please correct the errors in the file upload</p>
                        <ul>
                            {errors.csvData.split("\n").slice(1).map(item => {
                                return (<li>{item}</li>);
                            })}
                        </ul>
                </div>}
                <Row>
                    <Col>
                        <SelectField
                            id="userTypeId"
                            name="userTypeId"
                            label="User Type"
                            placeholder="Select user type"
                            options={this.state.userTypeOptions}
                            value={values.userTypeId}
                            isMulti={false}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.userTypeId}
                            error={errors.userTypeId}
                            isClearable={true}
                            backspaceRemovesValue={true}
                        />
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label className="input-label" htmlFor="file">File</label>

                            <input className="form-control-file" id="file" name="file" type="file" onChange={(event) => {
                                setFieldValue("file", event.currentTarget.files[0]);
                            }} />

                            <ErrorMessage name="file" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/invitations"} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Invite user
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Invite a user</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/users" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>

                    <div className="alert alert-info" style={{ marginTop: "30px" }}>
                        <Row>
                            <Col>
                                <p style={{ fontSize: "16px" }}>The file must be uploaded in a specific format, download this template for the uploads</p>
                            </Col>
                            <Col>
                                <a style={{float:"right"}} className="btn btn-info pull-right" href="/member-bulk-upload-template.csv">Download template</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var userTypes = (await MillbrookAPI.GetUserTypes(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                loading: false,
                next: false,
                userTypeOptions: userTypes.map(function (item) { return { value: item.id, label: item.name }; })
            });
        }
    }
}
