import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { MillbrookAPI } from '../Infrastructure/MillbrookAPI';

export class UserTypesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                enableReinitialize 
                initialValues={{
                    name: this.state.data.name,
                    foregroundColour: this.state.foregroundColour,
                    backgroundColour: this.state.backgroundColour,
                    termsLink: this.state.termsLink
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var response = await MillbrookAPI.UpdateUserType(this.props.match.params.id, fields.name, fields.backgroundColour, fields.foregroundColour, fields.termsLink);

                    if (!response.successful) {

                        response.data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }
                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="foregroundColour">Foreground Colour</label>
                                    <Field name="foregroundColour" type="text" className={'form-control' + (errors.foregroundColour && touched.foregroundColour ? ' is-invalid' : '')} />
                                    <ErrorMessage name="foregroundColour" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="backgroundColour">Background Colour</label>
                                    <Field name="backgroundColour" type="text" className={'form-control' + (errors.backgroundColour && touched.backgroundColour ? ' is-invalid' : '')} />
                                    <ErrorMessage name="backgroundColour" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="termsLink">Terms link</label>
                                    <Field name="termsLink" type="text" className={'form-control' + (errors.termsLink && touched.termsLink ? ' is-invalid' : '')} />
                                    <ErrorMessage name="termsLink" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/user-types/details/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update user type details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details of this user type</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/user-types/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var entity = await MillbrookAPI.GetUserTypeDetails(this.props.match.params.id);

            if (!entity.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                this.setState({
                    data: entity.data,
                    name: entity.data.Name,
                    foregroundColour: entity.data.foregroundColour,
                    backgroundColour: entity.data.backgroundColour,
                    termsLink: entity.data.termsLink,
                    loading: false,
                    init: true
                });

            }
        }
    }
}
