import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/layout/Layout';
import { SignIn } from './components/SignIn';
import { Home } from './components/Home';

import { UsersDetails } from './components/Users/UsersDetails';
import { UsersNew } from './components/Users/UsersNew';
import { UsersEdit } from './components/Users/UsersEdit';
import { Users } from './components/Users/Users';

import { Invitations } from './components/Invitations/Invitations';
import { InvitationsNew } from './components/Invitations/InvitationsNew';
import { InvitationsBulkUpload } from './components/Invitations/InvitationsBulkUpload';
import { InvitationsDetails } from './components/Invitations/InvitationsDetails';

import { UserTypes } from './components/UserTypes/UserTypes';
import { UserTypesDetails } from './components/UserTypes/UserTypesDetails';
import { UserTypesNew } from './components/UserTypes/UserTypesNew';
import { UserTypesEdit } from './components/UserTypes/UserTypesEdit';

import './custom.css'
import { PrivateRoute } from './components/Infrastructure/PrivateRoute';

export default class App extends Component {

    render() {
        return (
            <Layout>

                <Route exact path='/sign-in' component={SignIn} />

                <PrivateRoute exact path='/' component={Home} />

                <PrivateRoute exact path='/users/new' component={UsersNew} />
                <PrivateRoute exact path='/users/edit/:id' component={UsersEdit} />
                <PrivateRoute exact path='/users/details/:id' component={UsersDetails} />
                <PrivateRoute exact path='/users' component={Users} />

                <PrivateRoute exact path='/user-types/new' component={UserTypesNew} />
                <PrivateRoute exact path='/user-types/edit/:id' component={UserTypesEdit} />
                <PrivateRoute exact path='/user-types' component={UserTypes} />
                <PrivateRoute exact path='/user-types/details/:id' component={UserTypesDetails} />

                <PrivateRoute exact path='/invitations/new' component={InvitationsNew} />
                <PrivateRoute exact path='/invitations/bulk-upload' component={InvitationsBulkUpload} />
                <PrivateRoute exact path='/invitations' component={Invitations} />
                <PrivateRoute exact path='/invitations/details/:id' component={InvitationsDetails} />

            </Layout>
        );
    }
}
