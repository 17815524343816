import { MillbrookAPI } from "./MillbrookAPI";

export async function GetAPIDomain() {
    var domain = localStorage.getItem("api-domain");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainApi;
        localStorage.setItem("api-domain", domain);
    }

    return domain;
}

export async function GetAPIHeadersTokenOnly() {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await MillbrookAPI.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");
    return new Headers({ 'Authorization': 'Bearer ' + accessToken });
}

export async function GetAPIHeaders(contentType) {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await MillbrookAPI.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");
    return new Headers({ 'content-type': contentType ? contentType : 'application/json', 'Authorization': 'Bearer ' + accessToken });
}


export async function GetAPIHeadersPatch() {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await MillbrookAPI.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");

    return new Headers({ 'content-type': 'application/json-patch+json', 'Authorization': 'Bearer ' + accessToken });
}