import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Alert } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { MillbrookAPI, ListQueryParams } from '../Infrastructure/MillbrookAPI';
import { SelectField } from '../Infrastructure/SelectField';

export class UsersEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialUserType = this.state.userTypeOptions.filter(type => type.value === this.state.userTypeId)[0];

            return (<Formik
                enableReinitialize
                initialValues={{
                    name: this.state.data.name,
                    mobile: this.state.data.mobile,
                    membershipNumber: this.state.data.membershipNumber,
                    email: this.state.data.email,
                    userTypeId: initialUserType,
                    role: this.state.data.role,
                    password: ""
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.userTypeId) {
                        setFieldError("userTypeId", "Please specify a type for this user");
                        return;
                    }

                    var response = await MillbrookAPI.UpdateUser(this.props.match.params.id, fields.name, fields.mobile, fields.membershipNumber, fields.email, fields.userTypeId.value, fields.role);

                    if (!response.successful) {

                        response.data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {

                        if (fields.password.length > 0)
                            await MillbrookAPI.SetUserPassword(this.props.match.params.id, fields.password);

                        this.setState({ next: true });
                    }
                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleBlur, handleChange }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="membershipNumber">Membership Number</label>
                                <Field name="membershipNumber" type="text" className={'form-control' + (errors.membershipNumber && touched.membershipNumber ? ' is-invalid' : '')} />
                                <ErrorMessage name="membershipNumber" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile</label>
                                <Field name="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SelectField
                                id="userTypeId"
                                name="userTypeId"
                                label="User Type"
                                placeholder="Select user type"
                                options={this.state.userTypeOptions}
                                value={values.userTypeId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.userTypeId}
                                error={errors.userTypeId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="role">Role</label>

                                <select type="select" name="role"
                                    value={values.role}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.role && touched.role ? ' is-invalid' : '')}>
                                    <option value="1">Member</option>
                                    <option value="2">Admin</option>
                                </select>

                                <ErrorMessage name="role" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    {values.role == "2" && <Alert color="warning" className="mt-2">
                        <h6>Leave the password field empty if you do not want to change the password</h6>
                        <div className="form-group">
                                <label htmlFor="password">New Password</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                    </Alert>}

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/users/details/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update user details
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details of this user</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/users/details/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var entity = await MillbrookAPI.GetUserDetails(this.props.match.params.id);
            var userTypes = (await MillbrookAPI.GetUserTypes(new ListQueryParams().Paginate(0, 10000))).data.data;


            var userTypeId = null;

            if (entity.data.userType)
                userTypeId = entity.data.userType.id;

            if (!entity.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                this.setState({
                    data: entity.data,
                    userTypeId: userTypeId,
                    loading: false,
                    init: true,
                    userTypeOptions: userTypes.map(function (item) { return { value: item.id, label: item.name }; })
                });

            }
        }
    }
}
