import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export class Home extends Component {


    render() {
        return (<Redirect to="/users" />);
    }
}
