import React from 'react';
import { Route } from 'react-router-dom';
import { SignIn } from '../SignIn';
import { MillbrookAPI } from './MillbrookAPI';

export const PrivateRoute = ({ component: Component, ...rest }) => {

    if (MillbrookAPI.IsAuthenticated()) {

        return (
            <Route {...rest} render={props => (
                <Component {...props} />
            )} />);
    } else {
        console.log("RENDER SIGN IN");
        return (
            <Route {...rest} render={() => (
                <SignIn />
            )} />);
    }
};