import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, CardBody, Row, Col, Button, Badge } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import { MillbrookAPI } from '../Infrastructure/MillbrookAPI';
import { InvitationsTable } from '../Invitations/InvitationsTable';

export class UsersDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.sendInvitation = this.sendInvitation.bind(this);
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async sendInvitation() {
        this.setState({
            inviteLoading: true
        });

        await MillbrookAPI.ResendInvitation(this.props.match.params.id);

        this.setState({
            inviteLoading: false,
            inviteSent: true
        });
    }

    async deleteUser() {
        this.setState({
            deleteLoading: true
        });

        await MillbrookAPI.DeleteUser(this.props.match.params.id);

        this.setState({
            deleteLoading: false
        });

        window.location.href = "/users";

    }
    render() {

        return (
            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>
                                    <Link to="/users" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                    {this.state.data && <span>User details</span>}
                                    {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                </h5>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    {this.state.data && <Link style={{ float: "right" }} to={"/users/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    <Link onClick={() => { this.sendInvitation(); }} style={{ float: "right" }} to={"/users/details/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">
                                        {this.state.inviteLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        {!this.state.inviteLoading && "Send invitation"}
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                {this.state.inviteSent &&
                    <div className="alert alert-success" style={{ marginTop: "30px" }}>
                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>A new invite has been sent to this user.</p>
                    </div>}

                <Card>
                    <CardHeader>
                        <Nav pills style={{ boxShadow: "none" }}>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}>
                                    Details
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}>
                                    Invitations
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggle('3'); }}>
                                    Administration
                            </NavLink>
                            </NavItem>
                        </Nav>

                    </CardHeader>
                </Card>


                <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                    <TabPane tabId="1">
                        {this.state.activeTab === '1' && this.state.data && <Card>
                            <CardBody>
                                <h6>Details</h6>
                                <hr />

                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">Name:</td>
                                            <td>
                                                {this.state.data.name && this.state.data.name}
                                                {!this.state.data.name && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Mobile:</td>
                                            <td>
                                                {this.state.data.mobile && this.state.data.mobile}
                                                {!this.state.data.mobile && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Membership #:</td>
                                            <td>
                                                {this.state.data.membershipNumber && this.state.data.membershipNumber}
                                                {!this.state.data.membershipNumber && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Email:</td>
                                            <td>
                                                {this.state.data.email && this.state.data.email}
                                                {!this.state.data.email && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Role:</td>
                                            <td>
                                                {this.state.data.role == 0 && "None"}
                                                {this.state.data.role == 1 && "Member"}
                                                {this.state.data.role == 2 && "Admin"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">User Type:</td>
                                            <td>
                                                {this.state.data.userType && <Link key={this.state.data.userType.id + "-view"} to={"/user-types/details/" + this.state.data.userType.id}>{this.state.data.userType.name}</Link>}
                                                {!this.state.data.userType && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Created:</td>
                                            <td>
                                                {this.state.data.created && <Moment utc local format="ddd DD MMM, h:mm a">{this.state.data.created}</Moment>}
                                                {!this.state.data.created && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>}
                    </TabPane>
                    <TabPane tabId="2">
                        {this.state.activeTab === '2' && <InvitationsTable userId={this.props.match.params.id} />}
                    </TabPane>
                    <TabPane tabId="3">
                        {this.state.activeTab === '3' && this.state.data && <Card>
                            <CardBody>
                                <h6>Delete user</h6>
                                <hr />
                                <p>This is a permanent action, if you delete someone by mistake your only recorse is to add them as a new member again.</p>
                                <hr />
                                <Link onClick={() => { this.deleteUser(); }} style={{ float: "right" }} to="#" className="btn mr-2 btn-danger mt-2 mb-2">
                                        {this.state.deleteLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        {!this.state.deleteLoading && "Delete user"}
                                    </Link>
                            </CardBody>
                        </Card>}
                    </TabPane>
                </TabContent>


            </div>
        );
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await MillbrookAPI.GetUserDetails(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }
}
