import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { MillbrookAPI } from '../Infrastructure/MillbrookAPI';

export class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar navbar-dark bg-dark flex-md-nowrap shadow" dark style={{ paddingTop: "10px", paddingBottom: "10px" }}>

                    <NavbarBrand style={{ textAlign: "left" }} className="navbar-brand col-sm-3 col-md-2 bg-dark" tag={Link} to="/">Millbrook</NavbarBrand>

                    <ul className="navbar-nav px-3">
                        {MillbrookAPI.IsAuthenticated() &&
                            <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/" onClick={() => { MillbrookAPI.LogOut(); }}>Sign out</NavLink>
                            </NavItem>}

                        {!MillbrookAPI.IsAuthenticated() &&
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/sign-in">Sign in</NavLink>
                            </NavItem>
                        }
                </ul>

                </Navbar>
            </header>
        );
    }
}
