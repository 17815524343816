import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import { TableHeader } from '../Infrastructure/TableHeader';
import { MillbrookAPI, UserQueryParams } from '../Infrastructure/MillbrookAPI';
import Moment from 'react-moment';

export class Users extends Component {

    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleUsedAppChange = this.handleUsedAppChange.bind(this);

        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", sort: "name", direction: "asc"
        };
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    async clear() {

        this.setState({
            role: "",
            usedApp: "",
            search: ""
        }, () => this.update());

    }

    handleRoleChange(evt) {
        this.setState({
            role: evt.target.value
        }, () => this.update());
    }

    handleUsedAppChange(evt) {
        this.setState({
            usedApp: evt.target.value
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "#", sort: "membershipNumber" },
                        { name: "Name", sort: "name" },
                        { name: "Email", sort: "email" },
                        { name: "Mobile", sort: "mobile" },
                        { name: "Last used app", sort: "appLastUsed" },
                        { name: "Role", sort: "role", static: true },
                        { name: "Type", sort: "userType.name" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />

                <tbody>
                    {data.map(user =>
                        <tr key={user.id}>
                            <td>{user.membershipNumber}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                                {user.mobile && <span>{user.mobile}</span>}
                                {!user.mobile && <i>Not specified</i>}
                            </td>
                            <td>
                                {user.appLastUsed && <Moment format="ddd DD MMM, H:mma">
                                    {user.appLastUsed}
                                </Moment>}
                                {!user.appLastUsed && <i>Not used</i>}
                            </td>
                            <td>
                                <span>
                                    {user.role === 0 && "None"}
                                    {user.role === 1 && "Member"}
                                    {user.role === 2 && "Admin"}
                                </span>
                            </td>
                            <td>
                                {user.userType && <span>{user.userType.name}</span>}
                                {!user.userType && <i>Not specified</i>}
                            </td>
                            <td>
                                <Link key={user.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/users/details/" + user.id}>Details</Link>
                                <Link key={user.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/users/edit/" + user.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No users to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Users ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of users who can access the system.</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link onClick={() => { this.exportUsers(); return false; }} style={{ float: "right" }} to="#" className="btn mr-2 btn-outline-dark mt-2 mb-2">Export</Link>
                                        <Link style={{ float: "right" }} to="/users/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>

                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.role} onChange={this.handleRoleChange} >
                                            <option value="">All roles</option>
                                            <option value="0">None</option>
                                            <option value="1">Member</option>
                                            <option value="2">Admin</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.usedApp} onChange={this.handleUsedAppChange} >
                                            <option value="">App usage status</option>
                                            <option value="True">Used the app</option>
                                            <option value="False">Has not used the app</option>
                                        </Input>
                                    </InputGroup>
                                </Col>

                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.role || this.state.usedApp) &&
                                                    <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async exportUsers() {
        var response = await MillbrookAPI.ExportUsers(new UserQueryParams()
            .Search(this.state.search)
            .WithRole(this.state.role)
            .WithUsedApp(this.state.usedApp)
            .Sort(this.state.sort, this.state.direction));

        return false;
        // if (response.authenticated) {
        //     window.location = response.data;
        // } else {
        //     this.setState({
        //         authenticated: false
        //     });
        // }
    }   

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await MillbrookAPI.GetUsers(new UserQueryParams()
                .Paginate(skip, take)
                .Search(this.state.search)
                .WithRole(this.state.role)
                .WithUsedApp(this.state.usedApp)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
