import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import { MillbrookAPI } from '../Infrastructure/MillbrookAPI';

export class InvitationsDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false };
        this.revoke = this.revoke.bind(this);
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    async revoke() {
        this.setState({
            revokeLoading: true
        });

        await MillbrookAPI.RevokeInvitation(this.props.match.params.id);
        this.populateData(this.props.match.params.id);


        this.setState({
            revokeLoading: false
        });
    }

    render() {

        return (
            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>
                                    <Link to="/invitations" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                    {this.state.data && <span>Invitation</span>}
                                    {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                </h5>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">Name:</td>
                                            <td>
                                                {this.state.data.name && this.state.data.name}
                                                {!this.state.data.name && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Email:</td>
                                            <td>
                                                {this.state.data.email && this.state.data.email}
                                                {!this.state.data.email && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Mobile:</td>
                                            <td>
                                                {this.state.data.mobile && this.state.data.mobile}
                                                {!this.state.data.mobile && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Membership #:</td>
                                            <td>
                                                {this.state.data.membershipNumber && this.state.data.membershipNumber}
                                                {!this.state.data.membershipNumber && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Role:</td>
                                            <td>
                                                {this.state.data.role == 0 && "None"}
                                                {this.state.data.role == 1 && "Member"}
                                                {this.state.data.role == 2 && "Admin"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">User Type:</td>
                                            <td>
                                                {this.state.data.userType && <Link key={this.state.data.userType.id + "-view"} to={"/user-types/details/" + this.state.data.userType.id}>{this.state.data.userType.name}</Link>}
                                                {!this.state.data.userType && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">User:</td>
                                            <td>
                                                {this.state.data.user && <Link key={this.state.data.user.id + "-view"} to={"/users/details/" + this.state.data.user.id}>{this.state.data.user.name}</Link>}
                                                {!this.state.data.user && <i>Not specified</i>}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="pr-2">Expiry:</td>
                                            <td>
                                                <Moment format="ddd DD MMM, H:mma">
                                                    {this.state.data.expiry}
                                                </Moment>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="pr-2">Used:</td>
                                            <td>
                                                {this.state.data.used && <Moment format="ddd DD MMM, H:mma">
                                                    {this.state.data.used}
                                                </Moment>}
                                                {!this.state.data.used && <i>Not used</i>}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="pr-2">Status:</td>
                                            <td>
                                                {this.state.data.status === 2 && <Badge color="secondary">Used</Badge>}
                                                {this.state.data.status === 0 && <Badge color="success">Active</Badge>}
                                                {this.state.data.status === 1 && <Badge color="secondary">Expired</Badge>}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="pr-2">Created:</td>
                                            <td>
                                                {this.state.data.created && <Moment format="ddd DD MMM, H:mma">
                                                    {this.state.data.created}
                                                </Moment>}
                                                {!this.state.data.created && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </Col>
                        </Row>
                    </CardHeader>
                </Card>



                {this.state.data.status === 0 && <div className="alert alert-secondary" style={{ marginTop: "30px" }}>
                    <Row>
                        <Col>
                            <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This invite <strong>active</strong>, you can revoke the invite if you do not want it to be used.</p>
                        </Col>
                        <Col md="2">
                            <Button style={{ float: "right" }} to="/notices" onClick={this.revoke} className="btn mr-2 mt-2 mb-2 btn-seconday">
                                {this.state.revokeLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                {!this.state.revokeLoading && "Revoke"}
                            </Button>
                        </Col>
                    </Row>
                </div>}
            </div>
        );
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await MillbrookAPI.GetInvitationDetails(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }
}
