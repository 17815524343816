import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { MillbrookAPI, ListQueryParams } from '../Infrastructure/MillbrookAPI';
import { SelectField } from '../Infrastructure/SelectField';

export class InvitationsNew extends Component {

    constructor(props) {
        super(props);
        this.state = { apartmentOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                mobile: "",
                membershipNumber: "",
                email: "",
                userTypeId: "",
                role: 1
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.userTypeId) {
                    setFieldError("userTypeId", "Please specify a type for this user");
                    return;
                }

                var response = await MillbrookAPI.CreateInvitation(fields.name, fields.mobile, fields.membershipNumber, fields.email, fields.userTypeId.value, fields.role);

                if (!response.successful) {

                    response.data.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleBlur, handleChange }) => (
            <Form>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="membershipNumber">
                                    {values.role == "2" && "Password"}
                                    {values.role != "2" && "Membership Number"}
                                </label>
                                <Field name="membershipNumber" type="text" className={'form-control' + (errors.membershipNumber && touched.membershipNumber ? ' is-invalid' : '')} />
                                <ErrorMessage name="membershipNumber" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile</label>
                                <Field name="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SelectField
                                id="userTypeId"
                                name="userTypeId"
                                label="User Type"
                                placeholder="Select user type"
                                options={this.state.userTypeOptions}
                                value={values.userTypeId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.userTypeId}
                                error={errors.userTypeId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="role">Role</label>

                                <select type="select" name="role"
                                    value={values.role}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.role && touched.role ? ' is-invalid' : '')}>
                                    <option value="1">Member</option>
                                    <option value="2">Admin</option>
                                </select>

                                <ErrorMessage name="role" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/invitations/details/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Invite user
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Invite a user</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/users" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var userTypes = (await MillbrookAPI.GetUserTypes(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                loading: false,
                next: false,
                userTypeOptions: userTypes.map(function (item) { return { value: item.id, label: item.name }; })
            });
        }
    }
}
