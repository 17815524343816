import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import { TableHeader } from '../Infrastructure/TableHeader';
import { MillbrookAPI, UserQueryParams, InvitationQueryParams } from '../Infrastructure/MillbrookAPI';

export class InvitationsTable extends Component {

    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);

        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", sort: "name", direction: "asc"
        };
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    async clear() {

        this.setState({
            status: "",
            search: ""
        }, () => this.update());

    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    renderTable(data) {

        const {
            userId
        } = this.props;

        var columns = [
            { name: "Name", sort: "name" },
            { name: "Email", sort: "email" },
            { name: "Type", sort: "userType.name" },
            { name: "Used", sort: "used" },
            { name: "Expiry", sort: "expiry" },
            { name: "Status", sort: "", static: true }];

        if (!userId)
            columns.push({ name: "User", sort: "user.name" });
        columns.push({ name: "", sort: "", static: true });

        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={columns}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />

                <tbody>
                    {data.map(entity =>
                        <tr key={entity.id}>
                            <td>{entity.name}</td>
                            <td>{entity.email}</td>
                            <td>
                                {entity.userType && <span>{entity.userType.name}</span>}
                                {!entity.userType && <i>Not specified</i>}
                            </td>
                            <td>
                                {entity.used && <Moment format="ddd DD MMM, H:mma">
                                    {entity.used}
                                </Moment>}
                                {!entity.used && <i>Not used</i>}
                            </td>
                            <td>
                                <Moment format="ddd DD MMM, H:mma">
                                    {entity.expiry}
                                </Moment>
                            </td>
                            <td>
                                {entity.status === 2 && <Badge color="secondary">Used</Badge>}
                                {entity.status === 0 && <Badge color="success">Active</Badge>}
                                {entity.status === 1 && <Badge color="secondary">Expired</Badge>}
                            </td>

                            {!userId && <td>
                                {entity.user && <Link key={entity.user.id + "-view"} to={"/users/details/" + entity.user.id}>{entity.user.name}</Link>}
                                {!entity.user && <i>Not linked</i>}
                            </td>}

                            <td>
                                <Link key={entity.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/invitations/details/" + entity.id}>Details</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>No invitations to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {

        const {
            userId
        } = this.props;

        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Invitations ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of invitations sent out.</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                        {!userId && <>
                                            <Link style={{ float: "right" }} to="/invitations/bulk-upload" className="btn mr-2 btn-outline-dark mt-2 mb-2">Bulk upload</Link>
                                            <Link style={{ float: "right" }} to="/invitations/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                        </>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="0">Valid</option>
                                            <option value="1">Expired</option>
                                            <option value="2">Used</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.status) &&
                                                    <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>

                </div>
            );
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {

        const {
            userId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await MillbrookAPI.GetInvitations(new InvitationQueryParams()
                .Paginate(skip, take)
                .Search(this.state.search)
                .WithUser(userId)
                .WithStatus(this.state.status)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
